const data = {
  "kids-encyclopedia":
  {
    icon: "/icons/KidsEncyclopedia-512-yellow.png",
    title: "Kids Encyclopedia",
    description: "The Encyclopedia for Kids! Explore & learn about Science, History, Nature, Geography, Art & Technology.\n\nLearn about the Solar System, Tutankhamen, The Eiffel Tower, The Seven Continents, Michelangelo, Dolphins, Electric Lighting (Light Bulbs to LEDs), The Space Shuttle and more!",
    features: [
      "Powered by AI"
    ],
    screenshots: [
      "/images/kids-encyclopedia/1.webp",
      "/images/kids-encyclopedia/2.webp",
      "/images/kids-encyclopedia/3.webp",
      "/images/kids-encyclopedia/4.webp",
    ],
    googlePlayLink: "https://play.google.com/store/apps/details?id=com.sneakysharkstudios.kids_encyclopedia",
    appStoreLink: "https://apps.apple.com/tt/app/kids-encyclopedia/id1502045040",
    otherLinks: []
  },
  "nutricalc": {
    icon: "/icons/r3logo-v2-1024.png",
    title: "NutriCalc",
    description: "Get your custom meal nutritional data with this handy app!\nDesign your recipes before cooking, generate instant nutrition data and tweak your recipe ingredients accordingly. See the nutritional values in real time for your custom meals not just single ingredients.",
    features: [
      "Over 1400 foods from Australian Government Database",
      "Per serve and total calculations of your custom meals"
    ],
    screenshots: [
      "/images/nutricalc/new/1.webp",
      "/images/nutricalc/new/2.webp",
      "/images/nutricalc/new/3.webp",
      "/images/nutricalc/new/4.webp",
      "/images/nutricalc/new/5.webp",
      "/images/nutricalc/new/6.webp",
      "/images/nutricalc/new/7.webp",
      "/images/nutricalc/new/8.webp",
    ],
    googlePlayLink: "https://play.google.com/store/apps/details?id=com.r3nutricalc",
    appStoreLink: "https://apps.apple.com/us/app/nutricalc/id6692612621",
    otherLinks: []
  },
  "picture-charades": {
    icon: "/icons/picture-charades-icon.webp",
    title: "Picture Charades",
    description: "Want to try charades with pictures rather than just boring words?",
    features: [
      "First ever PICTURE charades game",
    ],
    screenshots: [
      "/images/picture-charades/1.webp",
      "/images/picture-charades/2.webp",
      "/images/picture-charades/3.webp",
      "/images/picture-charades/4.webp",
    ],
    googlePlayLink: "https://play.google.com/store/apps/details?id=com.sneakysharkstudios.picturecharades",
    appStoreLink: "https://apps.apple.com/us/app/picture-charades/id1537034574",
    otherLinks: []
  },
  "christmas-crackers": {
    icon: "/icons/cc_icon2.png",
    title: "Christmas Crackers",
    description: "Christmas Crackers is a simple game for this festive season that you can play with family and friends!\nEach time you play you have a 50% chance to win a toy, play with a friend and you or they are guaranteed to win a toy.\nCollect all the toys this Christmas with Christmas Crackers!",
    features: [
      "Firestore realtime database - infinitely scaling",
      "APIless backend",
      "Serverless cloud functions trigger on database activity running the game and producing toys",
    ],
    screenshots: [
      "/images/christmas-crackers/1.webp",
      "/images/christmas-crackers/2.webp",
      "/images/christmas-crackers/3.webp",
      "/images/christmas-crackers/4.webp",
      "/images/christmas-crackers/5.webp",
      "/images/christmas-crackers/6.webp",
    ],
    googlePlayLink: "https://play.google.com/store/apps/details?id=com.sneakysharkstudios.christmascrackers",
    appStoreLink: null,
    otherLinks: []
  },
  "apache-gunship": {
    icon: "/icons/Apache-Icon-2B-android-512.png",
    title: "Apache Gunship",
    description: "Fight through enemies in 5 different stages, rescue soldiers, upgrade your Apache helicopter. Blow up tanks, planes and artillery in this top down helicopter shooter.",
    features: [
      "Powered by Unity (C#) with physics."
    ],
    screenshots: [
      "/images/apache-gunship/1.jpg",
      "/images/apache-gunship/2.jpg",
      "/images/apache-gunship/3.jpg",
      "/images/apache-gunship/4.jpg",
      "/images/apache-gunship/5.jpg",
    ],
    googlePlayLink: "https://play.google.com/store/apps/details?id=com.sneakysharkstudios.apachegunship",
    appStoreLink: "https://itunes.apple.com/us/app/apache-gunship-1988/id1454089116",
    otherLinks: ["https://gameskeys.net/top-android-game-to-lookout-for-in-june-2020/#ApacheGunship"]
  },
  "binary-gaming-net": {
    icon: "/icons/BGN_Website_Logo.png",
    title: "Binary Gaming Net",
    description: "Binary Gaming Net website. Now considered legacy.",
    features: [
      "Discord plugin",
    ],
    screenshots: [
      "/images/binary-gaming-net/bgn1.png",
      "/images/binary-gaming-net/bgn2.png",
    ],
    googlePlayLink: null,
    appStoreLink: null,
    otherLinks: ["https://binarygaming.net"]
  },
  "recipe-club": {
    icon: "/icons/rcicon512.png",
    title: "Recipe Club",
    description: "Try out the Recipe Club app!\nShare, Discover and Learn all you favourite recipes without dealing with annoying ads.\nAdd your recipes and share them with your friends.\nDiscover recipes around the world from anybody using the app",
    features: [
      "Firestore realtime database - infinitely scaling",
      "APIless backend",
      "Multiple social signins",
    ],
    screenshots: [
      "/images/recipe-club/1.webp",
      "/images/recipe-club/2.webp",
      "/images/recipe-club/3.webp",
      "/images/recipe-club/4.webp",
      "/images/recipe-club/5.webp",
    ],
    googlePlayLink: "https://play.google.com/store/apps/details?id=com.sneakysharkstudios.recipeclub",
    appStoreLink: "https://apps.apple.com/us/app/recipe-club-app/id1513516529",
    otherLinks: []
  },
};

export function getAppData() {
    return data;
}